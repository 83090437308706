@mixin button($background-color: var(--color-secondary), $text-color: var(--color-text-primary), $autowidth: false) {
  @include textstyle-bold;
  height: 48px;
  padding: 0 24px;
  display: inline-block;
  border-radius: 24px;
  text-align: center;
  text-decoration: none;
  background-color: $background-color;
  color: $text-color;
  font-size: rem(16px);
  @include desktop {
    font-size: rem(24px);
  }
  line-height: rem(48px);
  cursor: pointer;
  visibility: visible;
  border: none;

  @if $autowidth == false {
    min-width: 280px;

    @include landscape {
      min-width: 320px;
    }
  }
  @else {
    min-width: 0;
  }

  &[disabled],
  &:disabled {
    cursor: default;
    pointer-events: none;
    user-select: none;
  }

  &[hidden] {
    visibility: hidden;
  }
}

@mixin button--secondary($background-color, $text-color) {
  @include button($background-color, $text-color, $autowidth: true);
  height: 40px;
  line-height: 40px;
}

@mixin button--tertiary($border-color, $text-color) {
  @include button(transparent, $text-color, $autowidth: false);
  box-sizing: border-box;
  border: 1px solid $border-color;
}

@mixin button--icon {
  width: 48px;
  padding: 0;
  min-width: 0;
}

@mixin button--info {
  position: relative;
  width: 24px;
  height: 13.86px;
  margin: 6.93px 0;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 14px;
  font-size: 12px;
  color: color(pageBtnText);

  // animation
  background-color: color(pageBtnBg);
  transition: background-color 100ms ease-in-out;
  z-index: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
  }

  &::before {
    bottom: 100%;

    // animation
    border-bottom: 6.93px solid #fff;
    transition: border-bottom-color 100ms ease-in-out;
  }

  &::after {
    top: 100%;
    width: 0;

    // animation
    border-top: 6.93px solid #fff;
    transition: border-top-color 100ms ease-in-out;
    z-index: -1;
  }
}
