.permission {
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $z-index-prompt;

  @include landscape {
    // padding: 32px;
  }

  &__content {
    width: 100%;
    max-width: 380px;
  }

  // temp
  a {
    cursor: default;
  }

  &__image {

    img {
      width: 100%;
    }
  }
}