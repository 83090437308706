@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function rem($value) {
	$root: 16;
	$val: parseInt($value);
	$return: ();

	@if unit($value) == "px" {
		$return: append($return, ($val / $root + rem));
	} @else {
		$return: append($return, ($val * $root + px));
	}

	@return $return;
}