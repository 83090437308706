i {
  font-family: $font-material-icons;
  font-style: normal;
  display: inline-block;
  text-transform: none;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Font Awesome

i::after {
  font-family: $font-regular-icons, $font-solid-icons, $font-brand-icons;
}

// gui
i.icon-camera::after { content: "\f030"; }
i.icon-check::after { content: "\f00c"; }
i.icon-chevron-down::after { content: "\f078"; }
i.icon-chevron-left::after { content: "\f053"; }
i.icon-chevron-right::after { content: "\f054"; }
i.icon-close::after { content: "\f00d"; }
i.icon-info::after { content: "\f129"; }
i.icon-plus::after { content: "\f067"; }

// emotions
i.icon-emotion-0::after, i.icon-emotion-E::after { content: "\f599"; } // laugh (strongly agree)
i.icon-emotion-1::after, i.icon-emotion-D::after { content: "\f118"; } // smile (agree)
i.icon-emotion-2::after, i.icon-emotion-C::after { content: "\f11a"; } // meh (neither nor)
i.icon-emotion-3::after, i.icon-emotion-B::after { content: "\f119"; } // frown (disagree)
i.icon-emotion-4::after, i.icon-emotion-A::after { content: "\f5c8"; } // tired (strongly disagree)
// 6 point likert scale emotions
i.likert6.icon-emotion-F::after { content: "\f599"; } // laugh (strongly agree)
i.likert6.icon-emotion-E::after { content: "\f118"; } // smile (agree)
i.likert6.icon-emotion-D::after { content: "\f11a"; } // meh (neither nor)
i.likert6.icon-emotion-C::after { content: "\f11a"; } // meh (neither nor)
i.likert6.icon-emotion-B::after { content: "\f119"; } // frown (disagree)
i.likert6.icon-emotion-A::after { content: "\f5c8"; } // tired (strongly disagree)

// services
i.icon-facebook::after { content: "\f39e"; }
i.icon-instagram::after { content: "\f16d"; }
i.icon-linkedin::after { content: "\f0e1"; }
i.icon-twitter::after { content: "\f099"; }
i.icon-euro-sign::after { content: "\f153"; }
i.icon-bank::after { content: "\f594"; }
i.icon-device::after { content: "\f1de"; }
