.watermark {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;

  .hexagon-left,
  .hexagon-center,
  .hexagon-right {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    polygon {
      transform: rotate(105deg);
      transform-origin: 50% 50%;
    }
  }

  .hexagon-left {
    transform: translate(-50%, 60%);
  }

  .hexagon-center {
    transform: translate(0, 20%);
  }

  .hexagon-right {
    transform: translate(50%, 60%);
  }
}