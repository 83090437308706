.giphy {
  display: flex;
  justify-content: center;

  figure {
    height: 196px;
    overflow: hidden;
    width: 196px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  img {
    height: 100% !important;
    width: auto !important;
  }
}