.page {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    position: relative;
    width: 100%;
  }

  &__header {
    margin-top: 24px;
    max-width: 320px;
    width: 100%;
    padding: 0 20px;

    @include portrait {
      margin-top: 64px;
      padding: 0 24px;
    }

    @include landscape {
      margin-top: 96px;
      max-width: 480px;
    }
  }

  &__content {
    margin: 32px 0;
    width: 100%;
    padding: 0 20px;

    @include portrait {
      padding: 0 24px;
      margin: 48px 0;
      max-width: 480px;
    }

    &-columns {
      text-align: center;
    }
  }

  &__footer {
    text-align: center;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 32px;

    @include portrait {
      padding: 0 24px 64px;
      max-width: 480px;
      padding-bottom: 112px;
    }
  }

  &__title {
    text-align: center;
    margin: 16px;

    @include portrait {
      margin-bottom: 24px;
    }
  }

  &__text {
    text-align: center;
    margin: 16px;
  }

  &__image {
    margin: 32px 0;

    img {
      width: 100%;
    }
  }

  &__next-button {
    margin: 8px 0;
    text-align: center;
  }

  &__back-button {
    @include button--tertiary( color(pageBtnBg), color(pageBtnText) );
    margin: 8px 0;
  }

  &__link {
    @include typography-note;
    text-decoration: none;
    margin: 8px 0;
  }
}