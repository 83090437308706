@mixin clearfix {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

// minus
@mixin mobile() {
  @media all {
    @content;
  }
}

@mixin mobile-landscape() {
  @media all and (max-height: $breakpoint-portrait) {
    @content;
  }
}

@mixin portrait() {
  @media all and (min-width: $breakpoint-portrait) {
    @content;
  }
}

@mixin landscape() {
  @media all and (min-width: $breakpoint-landscape) {
    @content;
  }
}

@mixin desktop() {
  @media all and (min-width: $breakpoint-desktop) {
    @content;
  }
}

// onlies
@mixin mobile-only() {
  @media all and (min-width: 0) and (max-width: $breakpoint-portrait) {
    @content;
  }
}

@mixin portrait-only() {
  @media all and (min-width: $breakpoint-portrait) and (max-width: $breakpoint-landscape) {
    @content;
  }
}

// breakpoint maxes
@mixin portrait-max() {
  @media all and (max-width: $breakpoint-portrait) {
    @content;
  }
}

@mixin landscape-max() {
  @media all and (max-width: $breakpoint-landscape) {
    @content;
  }
}

@mixin desktop-max() {
  @media all and (max-width: $breakpoint-desktop) {
    @content;
  }
}