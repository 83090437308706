.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__header {
    position: relative;
    margin-top: 24px;
    text-align: center;
    padding: 0 24px 24px;
    min-height: 160px;
    max-width: 100%;

    // animation
    transition: height 300ms ease-in-out;

    @include portrait {
      margin-top: 48px;
    }
    @include mobile-landscape {
      margin-top: 0;
      max-width: 67%;
    }
    @include landscape {
      margin-top: 0;
      max-width: 67%;
    }
    @include desktop {
      max-width: 50%;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: color(questionBg);
    width: 100%;
    position: relative;
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    content: '';
    width: 100%;
    border-top: 40px solid transparent;
    z-index: $step-header;
  }

  &__footer {
    background-color: color(questionBg);
    width: 100%;
    padding: 0;
    text-align: center;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__indicator {
    position: absolute;
    left: 0;
    right: 0;

    // animation
    bottom: 0;
    transition: bottom 300ms ease-in-out;
  }

  &__image {
    margin-bottom: 24px;
    max-width: 500px;

    @include mobile-only {
      padding: 0 16px;
    }

    img {
      width: 100%;
    }
  }

  &__form {
    color: color(questionAnswerText);
    width: 100%;
    max-width: 320px;
    padding: 0;
    z-index: $step-form;

    @include portrait {
      max-width: 480px;
    }

    @include landscape {
      padding: 24px;
    }

    .step-option {
      margin: 12px 0;
      &:last-child {
        margin: 12px 0 0 0;
      }
    }
  }

  &__submit-button {
    @include button(color(questionBtnBg), color(questionBtnText));
    margin: 16px 0;
  }

  // form elements
  .choice {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__instruction {
      width: 100%;
      text-align: center;
      display: block;
      margin-bottom: 32px;
    }

    &__buttons {
      width: 340px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include landscape {
        width: 360px;
      }
    }

    &__thumb {
      color: color(questionBg);
      width: 128px;
      height: 128px;
      text-align: center;
      vertical-align: bottom;
      background-color: color(questionAnswerHelper);
      position: absolute;
      border-radius: 50%;
      top: -16px;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: grab;
      user-select: none;

      i {
        font-size: rem(8px);
      }
    }
  }

  .range {
    position: relative;

    &__slider {
      position: absolute;
      top: -32px;

      &--min {
        left: 16px;

        @include portrait {
          left: 80px;
        }

        .range-slider__thumb::after {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid color(questionAnswerHelper);
          content: '';
          position: absolute;
          right: -6px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

      &--max {
        right: 16px;

        @include portrait {
          right: 80px;
        }

        .range-slider__thumb::after {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 10px solid color(questionAnswerHelper);
          content: '';
          position: absolute;
          left: -6px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    &__ruler {
      position: relative;
      width: 36px;
      margin: 6px auto auto auto;

      &-unit-max,
      &-unit-min {
        position: absolute;
        left: -8px;;
        transform: translateX(-100%);
        font-size: 14px;
        color: color(questionAnswerHelper);
        white-space: nowrap;
      }

      &-unit-max {
        top: -4px;
      }

      &-unit-min {
        bottom: -2px;
      }

      &-value {
        background-color: color(questionAnswerHelper);
        opacity: .5;
        height: 100%;
        width: 16px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
      }

      svg {
        z-index: 1;
        width: 36px;
        height: 256px;
        fill: color(questionHeadBg);
      }
    }

    &__instruction {
      margin-top: 32px;
      text-align: center;
    }
  }

  .range-slider {

    &__thumb {
      @include textstyle-bold;
      font-size: 14px;
      color: color(questionBg);
      width: 64px;
      height: 64px;
      background-color: color(questionAnswerHelper);
      padding: 22px 0;
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      user-select: none;
      position: relative;
    }
  }

  &--intro {

    .step__header {
      height: 65%;
    }

    &.is-introduced {

      .step__title {
        height: 0;
        opacity: 0;
      }

      .step__description {
        height: 0;
        opacity: 0;
      }

      // .step__indicator {
      //   bottom: 0;
      // }

      .step__header {
        height: 25%;
      }
    }
  }
}