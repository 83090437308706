.lightbox {
  background-color: color(promptOverlay);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $z-index-popup;

  &__content {
    background-color: color(promptBg);
    color: color(promptText);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 4px;
    width: 100%;
    position: relative;

    @include portrait {
      max-width: 360px;
    }
  }

  &__title {
    @include textstyle-bold;
    margin-bottom: 16px;
  }

  &__close-button {
    @include button--icon;
    width: 40px;
    height: 40px;
    line-height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    transform: translate(25%, -25%);
  }
}