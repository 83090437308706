.template-primary {

  &.page {
    background-color: color(pageBg);
    color: color(pageText);

    .page__next-button {
      @include button( color(pageBtnBg), color(pageBtnText) );
    }
  }
}