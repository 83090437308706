.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .swiper {

    &-container {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: inherit;
    }

    &-wrapper {
      min-height: 288px;
      max-height: 80%;
    }

    &-slide {}

    &-pagination {
      position: relative;
      bottom: auto;
      left: auto;

      &-bullet {
        background: color(pageText);
        opacity: .5;

        &-active {
          background: color(pageText);
          opacity: 1;
        }
      }
    }
  }

  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content {
      padding: 0 16px;
      max-width: 320px;

      @include portrait {
        padding: 0 24px;
        max-width: 480px;
      }
      @include desktop {
        max-width: 720px;
      }
    }
  }
}