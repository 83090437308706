// upper levels
$z-index-popup: 1001;
$z-index-prompt: 1000;

// page levels

// component levels
$step-form: 101;
$step-header: 100;

// base levels
$z-index-body: 1;
$z-index-html: 0;