.spinner {
  position: relative;
  height: 48px;
  width: 48px;
  margin: 24px auto;

  &-1,
  &-2 {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;

    // animation
    opacity: 0.6;
    transform: scale(1);
    animation: spinner-bounce 2000ms infinite ease-in-out;

    polygon {
      fill: color(loadText);
    }
  }

  &-2 {
    transform: scale(1);
    animation-delay: 600ms;
  }
}

@keyframes spinner-bounce {
  0%, 100% {
    transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
  }
}