$font-fallback: Helvetica;
$font-regular: 'Proxima Nova';
$font-bold: 'Proxima Nova Bold';
$font-semibold: 'Proxima Nova Semibold';
$font-extrabold: 'Proxima Nova Extrabold';

$font-material-icons: 'Material Icons';
$font-brand-icons: 'Font Awesome 5 Brands';
$font-regular-icons: 'Font Awesome 5 Free';
$font-solid-icons: 'Font Awesome 5 Solid';


// Proxima Nova
@font-face {
  font-family: 'Proxima Nova';
  src: assets-url('fonts/pn/pnr.woff2') format('woff2'), assets-url('fonts/pn/pnr.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: assets-url('fonts/pn/pnb.woff2') format('woff2'), assets-url('fonts/pn/pnb.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: assets-url('fonts/pn/pnsb.woff2') format('woff2'), assets-url('fonts/pn/pnsb.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Proxima Nova Extrabold';
  src: assets-url('fonts/pn/pneb.woff2') format('woff2'), assets-url('fonts/pn/pneb.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

// Material Icons
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: assets-url('fonts/mi/mir.woff2') format('woff2'), assets-url('fonts/mi/mir.woff') format('woff');
}

// Font Awesome
@font-face {
  font-family: 'Font Awesome 5 Solid';
  font-style: normal;
  font-weight: 900;
  src: assets-url('fonts/fa/fas.woff2') format('woff2'), assets-url('fonts/fa/fas.woff') format('woff');
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: assets-url('fonts/fa/far.woff2') format('woff2'), assets-url('fonts/fa/far.woff') format('woff');
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  src: assets-url('fonts/fa/fab.woff2') format('woff2'), assets-url('fonts/fa/fab.woff') format('woff');
}