@mixin textstyle-regular {
  font-family: var(--font-regular), var(--font-fallback);
  font-weight: 400;
}

@mixin textstyle-bold {
  font-family: var(--font-bold), var(--font-fallback);
  font-weight: 600;
}

@mixin textstyle-semibold {
  font-family: var(--font-semibold), var(--font-fallback);
  font-weight: 700;
}

@mixin textstyle-extrabold {
  font-family: var(--font-extrabold), var(--font-fallback);
  font-weight: 900;
}

