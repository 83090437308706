.template-step {

  &.page {
    background-color: color(questionHeadBg);
    color: color(questionHeadText);

    .page__container {
      width: 100%;
      max-width: none;
    }

    .page__header {
      margin-top: 0;
    }

    .page__content {
      flex: 1;
      margin: 0;
      display: flex;
      max-width: none;
      padding: 0;
      // flex-direction: column;
    }

    .page__footer { // Progress bar
      max-width: none;
      background-color: #fff;
      width: 100%;
      border-top: 1px solid #C0C0C0;
      padding: 0;
      align-items: flex-start;
    }
  }

  &__progress {
    background-color: color(progress);
    width: 0;
    height: 16px;
  }
}