.processing {
  background-color: color(loadBg);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $z-index-prompt;

  @include landscape {
    padding: 32px;
  }

  &__content {
    color: color(loadText);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    width: 100%;
    max-width: 360px;
    position: relative;
  }

  &__spinner {
    margin-bottom: 64px;
  }
}