.geolocation {

    &.page {
      background-color: color(pageBg);
      color: color(pageText);

      .page__container {
        width: 100%;
        max-width: none;
      }

      .page__header {
        margin-top: 0;
      }

      .page__content {
        flex: 1;
        margin: 0;
        display: flex;
        max-width: none;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .page__next-button {
        @include button( color(pageBtnBg), color(pageBtnText) );
      }
    }
}