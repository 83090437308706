.indicator {
  text-align: center;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: color(questionAnswerHelper);
  border: 4px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px auto;

  @include portrait {
    width: 144px;
    height: 144px;
    border-width: 8px;
  }

  &__unit {
    font-size: 16px;
    line-height: 16px;

    @include portrait {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__value {
    @include textstyle-extrabold;
    font-size: 32px;
    line-height: 24px;
    display: inline-block;

    @include portrait {
      font-size: 48px;
      line-height: 48px;
    }
  }

  &--timer {
    width: 32px;
    height: 32px;
    border-width: 4px;

    .indicator__value {
      opacity: 0;
    }

    .indicator__unit {
      opacity: 0;
    }
  }

  &__timer {
    position: absolute;
    top: bottom;
    left: 0;

    &.CircularProgressbar {
      width: 100%;
    }

    .CircularProgressbar-path {
      stroke: #fff;
      stroke-linecap: round;
      transition: stroke-dashoffset 0.5s ease 0s;
    }

    .CircularProgressbar-trail {
      stroke: color(questionAnswerHelper);
    }

    // .CircularProgressbar-text {
    //   fill: #3e98c7;
    //   font-size: 20px;
    //   dominant-baseline: middle;
    //   text-anchor: middle;
    // }
    //
    // .CircularProgressbar-background {
      // fill: red;
    // }
  }
}