.is-hidden {
  display: none;
}


h1 {
  @include typography-h1;
}

h2 {
  @include typography-h2;
}

h3 {
  @include typography-h3;
}

p {
  @include typography-paragraph;
}

button {
  @include button( color(pageBtnBg), color(pageBtnText) );
}