@mixin typography-h1 {
  @include textstyle-extrabold;
  font-size: rem(32px);
  line-height: rem(32px);
}

@mixin typography-h2 {
  @include textstyle-regular;
  font-size: rem(24px);
  line-height: rem(24px);
}

@mixin typography-h3 {
  @include textstyle-bold;
  font-size: rem(20px);
  line-height: rem(20px);
}

@mixin typography-paragraph {
  @include textstyle-regular;
  font-size: rem(16px);
  line-height: rem(20px);
  @include desktop {
    font-size: rem(24px);
    line-height: rem(30px);
  }
}

@mixin typography-note {
  @include textstyle-regular;
  font-size: rem(14px);
  line-height: rem(16px);
}

