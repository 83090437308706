#advice-robo-pcs {
  height: 100%;
  min-height: 559px; // Browser size for safari on iPhone 6/7/8 in portrait mode
  min-width: 320px;
  @include textstyle-regular;
  font-size: 16px;
  line-height: 24px;
  background: color(pageBg);
  color: #000;

  -webkit-font-smoothing: antialiased; // /* Support for all WebKit browsers. */
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; // /* Support for Safari and Chrome. */

  --font-fallback: #{$font-fallback};
  --font-regular: #{$font-regular};
  --font-bold: #{$font-bold};
  --font-semibold: #{$font-semibold};
  --font-extrabold: #{$font-extrabold};
}

// why :root and html?
html {
  height: 100%;
}

body {
  height: 100%;
}