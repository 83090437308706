.services {
  position: relative;

  @include portrait {
    padding-left: 40px; // add padding for the icon
  }

  &__privacy {
    margin-top: 16px;

    &-button {
      @include typography-note;
      color: color(pageText);
      text-decoration: none;
      height: inherit;
      padding: 0;
      min-width: inherit;
      background: none;

      i {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }

  .service {
    height: 56px;
    border-top: 1px solid #6767FF;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;

    @include portrait {
      height: 72px;
    }

    &:last-of-type {
      border-bottom: 1px solid #6767FF;
    }

    &.is-connected {

      .service__share-button {
        background-color: #00D45F;
        color: color(pageText);
        padding: 0;
        width: 40px;
        pointer-events: none;

        &-add,
        &-label {
          display: none;
        }

        &-check {
          display: inline-block;
        }
      }
    }

    &__type {
      width: 28px;
      height: 28px;
      margin-left: calc(-12px - 28px);
      margin-right: 12px;
      border-radius: 50%;
      text-align: center;
      background-color: #6767FF;
      font-size: 14px;
      color: #0000CC;
      line-height: 26px;

      @include mobile-only {
        display: none;
      }
    }

    &__name {
      flex: 1;
      vertical-align: middle;
      padding-top: 4px;

      @include mobile-only {
        font-size: 16px;
      }
    }

    &__info {
      @include button--info;
      margin-left: 12px;
      display: inline-block;
      font-size: 10px;
      line-height: 13px !important;
      vertical-align: middle;
      border-radius: inherit;
      min-width: inherit;
      padding: 0;
    }

    &__share-button {
      @include button--secondary( color(pageBtnBg), color(pageBtnText) );

      transition: width 200ms ease-in-out;

      &-check {
        font-size: 16px;
        line-height: 38px;
        margin-right: 0;
        display: none;
      }

      &-add {
        font-size: 12px;
        margin-right: 8px;
        line-height: 38px;
      }
    }
  }
}