.prompt {
  background-color: color(promptOverlay);
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $z-index-prompt;

  @include landscape {
    padding: 32px;
  }

  &__content {
    background-color: color(promptBg);
    color: color(promptText);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 4px;
    width: 100%;
    max-width: 360px;
  }

  &__title {
    @include textstyle-bold;
    margin-bottom: 4px;
  }

  &__message {
    margin-bottom: 24px;
  }

  &__submit {
    background-color: color(promptBtnBg);
    color: color(promptBtnText);
    @include portrait-max {
      min-width: 0;
    }
  }
}